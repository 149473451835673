import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import { round } from "../util/round";

// MUI
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import PayIcon from "@material-ui/icons/AttachMoney";
import theme from "../util/theme";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const useToolbarStyles = makeStyles((theme) => ({
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.main, 0.85),
        }
      : {
          color: theme.palette.text.primary,
        },
  title: {
    flex: "1 1 100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles(theme);
  const {
    numSelected,
    title,
    total,
    loading,
    handlePayCharges,
    handleDeleteCharges,
    handleFilterCharges,
    handleFilterReset,
    permissionType,
    resetDisabled,
  } = props;
  const [payDate, setPayDate] = useState(new Date());

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Total: ${round(total)}
        </Typography>
      ) : (
        <div />
      )}
      {numSelected > 0 ? (
        <div style={{ display: "contents" }}>
          {permissionType === "manager" && (
            <div style={{ display: "contents" }}>
              {!loading && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={payDate}
                    onChange={(data) => {
                      setPayDate(data);
                    }}
                    format="MM/dd/yy"
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
              )}
              <Tooltip title="Pay">
                <IconButton
                  aria-label="pay"
                  onClick={() => {
                    handlePayCharges(
                      payDate.toLocaleDateString("en-US", {
                        year: "numeric",
                        day: "2-digit",
                        month: "2-digit",
                      })
                    );
                    setPayDate(new Date());
                  }}
                >
                  <PayIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={() => {
                setPayDate(new Date());
                handleDeleteCharges();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <div style={{ display: "contents" }}>
          {resetDisabled && (
            <Button
              variant="outlined"
              style={{ marginRight: 10 }}
              onClick={() => handleFilterReset()}
            >
              Reset
            </Button>
          )}
          <Tooltip title="Filter">
            <IconButton onClick={() => handleFilterCharges()}>
              {resetDisabled ? (
                <FilterListIcon color="secondary" />
              ) : (
                <FilterListIcon />
              )}
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
