import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isManager } from "../util/permissions";

const ManagerRoute = ({
  component: Component,
  authenticated,
  permissionType,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated ? (
        isManager(permissionType) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  permissionType: state.user.credentials.permissionType,
});

export default connect(mapStateToProps)(ManagerRoute);
