import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import themeFile from "./util/theme";
import jwtDecode from "jwt-decode";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userActions";

// Components
import Navbar from "./components/Navbar";
import AuthRoute from "./util/AuthRoute";
import LoginRoute from "./util/LoginRoute";
import NotSubRoute from "./util/NotSubRoute";
import ManagerRoute from "./util/ManagerRoute";

// Pages
import home from "./pages/home";
import login from "./pages/login";
import add from "./pages/add";
import statement from "./pages/statement";
import statements from "./pages/statements";
import manage from "./pages/manage";

import axios from "axios";

const theme = createMuiTheme(themeFile);

// FIREBASE CLOUD FUNCTIONS API ROUTE
axios.defaults.baseURL =
  "https://us-central1-labor-backcharges-5-star.cloudfunctions.net/api";

const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Navbar />
          <div className="container">
            <Switch>
              <NotSubRoute exact path="/" component={home} />
              <LoginRoute exact path="/login" component={login} />
              <NotSubRoute exact path="/add" component={add} />
              <AuthRoute exact path="/statement" component={statement} />
              <AuthRoute exact path="/statements" component={statements} />
              <ManagerRoute exact path="/manage" component={manage} />
            </Switch>
          </div>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
