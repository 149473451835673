import React, { Component } from "react";

// components
import EnhancedTable from "../components/EnhancedTable";

// redux
import { connect } from "react-redux";
import { getStatements, setStatement } from "../redux/actions/dataActions";

const headers = [
  {
    label: "Responsibility",
    id: "responsibility",
    currency: false
  },
  {
    label: "Pay Date",
    id: "payDate",
    currency: false
  },
  {
    label: "Count",
    id: "count",
    currency: false
  },
  {
    label: "Total",
    id: "total",
    currency: true
  }
];

export class statements extends Component {
  state = {
    order: "desc",
    orderBy: "payDate",
    title: "Statements"
  };

  componentDidMount() {
    this.props.getStatements();
  }

  handleClick = (event, statement) => {
    this.props.setStatement(statement, this.props.history);
  };

  handleRequestSort = (event, property) => {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc" });
    this.setState({ orderBy: property });
  };

  render() {
    const { statements, getAllStatements, responsibilities } = this.props;
    return (
      <EnhancedTable
        rows={statements}
        headers={headers}
        title={
          getAllStatements
            ? "Statements"
            : responsibilities.length > 0
            ? "Statements: ".concat(responsibilities.join(", "))
            : "Statements"
        }
        footer={""}
        handleClick={this.handleClick}
        order={this.state.order}
        orderBy={this.state.orderBy}
        handleRequestSort={this.handleRequestSort}
        hover={true}
      />
    );
  }
}

const mapStateToProps = state => ({
  statements: state.data.statements,
  getAllStatements: state.user.credentials.getAllStatements,
  responsibilities: state.user.credentials.responsibilities
});

const mapActionsToProps = {
  getStatements,
  setStatement
};

export default connect(mapStateToProps, mapActionsToProps)(statements);
