// App reducer types
export const RESET_APP = "RESET_APP";

// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";

// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Data reducer types
export const SET_CHARGE = "SET_CHARGE";

export const SET_RAW_CHARGES = "SET_RAW_CHARGES";

export const RESET_CHARGES = "RESET_CHARGES";
export const FILTER_CHARGES = "FILTER_CHARGES";
export const CLEAR_CHARGES = "CLEAR_CHARGES";

export const SET_SELECTED = "SET_SELECTED";
export const CLEAR_SELECTED = "CLEAR_SELECTED";

export const SET_DROPDOWNS = "SET_DROPDOWNS";
export const CLEAR_DROPDOWNS = "CLEAR_DROPDOWNS";

export const SET_SELECTED_RES = "SET_SELECTED_RES";
export const CLEAR_SELECTED_RES = "CLEAR_SELECTED_RES";

export const SET_STATEMENT = "SET_STATEMENT";
export const CLEAR_STATEMENT = "CLEAR_STATEMENT";

export const SET_STATEMENTS = "SET_STATEMENTS";
export const CLEAR_STATEMENTS = "CLEAR_STATEMENTS";

export const SET_FILTERS = "SET_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
