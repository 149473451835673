import React, { useState } from "react";

// Util
import { stableSort, getSorting } from "../util/sorting";
import NumberFormat from "react-number-format";
import { round } from "../util/round";

// MUI
import withStyles from "@material-ui/core/styles/withStyles";
import { lighten } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import EnhancedTableHead from "../components/EnhancedTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import theme from "../util/theme";

const styles = {
  tableTitle: {
    marginRight: "25px",
    fontSize: 16,
    flex: "1 1 100%",
  },
  tableTitleContainer: {
    padding: "0 16px",
    borderBottom: "2px solid lightgray",
  },
  header: {
    marginRight: "10px",
    fontSize: 16,
  },
  tableText: {
    fontSize: 12,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableRowHovered: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.main, 0.85).concat(
        "!important"
      ),
    },
  },
  tableRow: {},
};

export const EnhancedTable = (props) => {
  const {
    classes,
    headers,
    rows,
    title,
    footer,
    handleClick,
    order,
    orderBy,
    handleRequestSort,
    hover,
    onDelete,
    permissionType,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  return (
    <Paper>
      <Toolbar className={classes.tableTitleContainer}>
        <Typography className={classes.tableTitle} variant="h6">
          {title}
        </Typography>
        {permissionType === "manager" && (
          <div style={{ display: "contents" }}>
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
      <Table size="small" cellPadding="2">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headers}
          checkboxPadding={false}
        />
        <TableBody>
          {stableSort(rows, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, i) => {
              return (
                <TableRow
                  key={i}
                  hover={hover}
                  onClick={(event) => {
                    handleClick(event, row);
                  }}
                  className={hover ? classes.tableRowHovered : classes.tableRow}
                >
                  {headers.map((header, index) =>
                    header.currency ? (
                      <TableCell
                        className={classes.tableText}
                        align="left"
                        key={index}
                      >
                        <NumberFormat
                          value={round(row[header.id])}
                          thousandSeparator
                          isNumericString
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType="text"
                        />
                      </TableCell>
                    ) : header.id === "attachment" ? (
                      <TableCell padding="checkbox" key={index}>
                        {row.attachment && (
                          <a
                            href={row.attachment}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton>
                              <AttachmentIcon fontSize="small" />
                            </IconButton>
                          </a>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell
                        className={classes.tableText}
                        align="left"
                        key={index}
                      >
                        {row[header.id]}
                      </TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => {
          setPage(newPage);
        }}
        onChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      <div
        style={{
          padding: "10px 30px",
          textAlign: "right",
        }}
      >
        <Typography className={classes.header} variant="h6">
          {footer}
        </Typography>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(EnhancedTable);
