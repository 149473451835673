import {
  SET_CHARGE,
  CLEAR_ERRORS,
  SET_ERRORS,
  LOADING_UI,
  SET_RAW_CHARGES,
  FILTER_CHARGES,
  SET_SELECTED,
  CLEAR_SELECTED,
  SET_DROPDOWNS,
  CLEAR_SELECTED_RES,
  SET_SELECTED_RES,
  SET_STATEMENT,
  SET_STATEMENTS,
  SET_FILTERS,
  CLEAR_FILTERS,
  RESET_CHARGES,
} from "../types";
import axios from "axios";

const isEmpty = (string) => {
  if (string.trim() === "") return true;
  else return false;
};

let validateCharge = (data, attachment) => {
  let errors = {};

  if (data.invoiceDate === null || isEmpty(data.invoiceDate))
    errors.invoiceDate = "Must not be empty";
  if (isEmpty(data.responsibility)) errors.responsibility = "Must not be empty";
  if (data.project === undefined || isEmpty(data.project))
    errors.project = "Must not be empty";
  if (isNaN(data.invoiceAmount) || data.invoiceAmount === "")
    errors.invoiceAmount = "Must not be empty";
  if (isEmpty(data.invoiceNumber)) errors.invoiceNumber = "Must not be empty";
  if (isEmpty(data.vendor)) errors.vendor = "Must not be empty";
  if (data.taxIncluded === null) errors.taxIncluded = "Must not be empty";
  if (data.taxRate === "" || isNaN(data.taxRate))
    errors.taxRate = "Must not be empty";
  if (isNaN(data.calculatedAmount) || data.calculatedAmount === "")
    errors.calculatedAmount = "Must not be empty";
  if (isEmpty(data.description)) errors.description = "Must not be empty";
  if (attachment === null) errors.attachment = "Must not be empty";
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false,
  };
};

let validateDropdown = (data) => {
  let errors = {};

  Object.keys(data).forEach((key) => {
    switch (key) {
      case "project":
      case "responsibility":
      case "vendor":
        if (data[key] === undefined || isEmpty(data[key]))
          errors["name"] = "Must not be empty";
        break;
      case "taxRate":
        if (data.taxRate === "" || isNaN(data.taxRate))
          errors.taxRate = "Must not be empty";
        break;
      default:
        break;
    }
  });
  return {
    errors,
    valid: Object.keys(errors).length === 0,
  };
};

export const createCharge = (charge, attachment, history) => (dispatch) => {
  dispatch({
    type: LOADING_UI,
  });
  const { valid, errors } = validateCharge(charge, attachment);
  if (valid) {
    axios
      .post("/attachment/upload", attachment)
      .then((res) => {
        let attachment = res.data.url;
        let filename = res.data.filename;
        axios
          .post("/charge", Object.assign(charge, { filename, attachment }))
          .then((res) => {
            dispatch({
              type: SET_CHARGE,
              payload: res.data,
            });
            history.push("/");
          })
          .catch((err) => {
            axios
              .delete("/attachment/delete", { filename })
              .then(() => {
                dispatch({
                  type: SET_ERRORS,
                  payload: err.response.data,
                });
              })
              .catch((err) => {
                dispatch({
                  type: SET_ERRORS,
                  payload: err.response.data,
                });
              });
          });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      });
  } else {
    dispatch({
      type: SET_ERRORS,
      payload: errors,
    });
  }
};

export const payCharges = (payment) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("charges/pay", payment)
    .then((res) => {
      dispatch(fetchCharges());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteCharges = (charges) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .delete("charges/delete", { data: charges })
    .then((res) => {
      dispatch(fetchCharges());
      dispatch({
        type: CLEAR_ERRORS,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const fetchCharges = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/charges")
    .then((res) => {
      dispatch({
        type: SET_RAW_CHARGES,
        payload: res.data,
      });
      dispatch({ type: FILTER_CHARGES });
      dispatch({
        type: CLEAR_SELECTED,
      });
      dispatch({
        type: CLEAR_SELECTED_RES,
      });
      dispatch({
        type: CLEAR_ERRORS,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setSelected = (charges, res) => (dispatch) => {
  dispatch({
    type: SET_SELECTED,
    payload: charges,
  });
  if (charges.length === 0) {
    dispatch({
      type: CLEAR_SELECTED_RES,
    });
  } else {
    dispatch({
      type: SET_SELECTED_RES,
      payload: res,
    });
  }
};

export const fetchDropdowns = () => (dispatch) => {
  axios
    .get("/dropdowns")
    .then((res) => {
      dispatch({
        type: SET_DROPDOWNS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createDropdown = (dropdown) => (dispatch) => {
  dispatch({
    type: LOADING_UI,
  });
  const { valid, errors } = validateDropdown(dropdown);

  if (valid) {
    axios
      .post("/dropdown", dropdown)
      .then(() => {
        fetchDropdowns();
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      });
  } else {
    dispatch({
      type: SET_ERRORS,
      payload: errors,
    });
  }
};

export const setStatement = (statement, history) => (dispatch) => {
  dispatch({
    type: SET_STATEMENT,
    payload: statement,
  });
  history.push("/statement");
};

export const createStatement = (statement) => (dispatch) => {
  axios
    .post("/statement", statement)
    .then((res) => {})
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getStatements = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/statements")
    .then((res) => {
      dispatch({
        type: SET_STATEMENTS,
        payload: res.data,
      });
      dispatch({
        type: CLEAR_ERRORS,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteStatement = (statementId, history) => (dispatch) => {
  dispatch({
    type: LOADING_UI,
  });
  console.log(statementId);
  axios
    .delete("/statement", { data: { statementId } })
    .then(() => {
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setFilters = (filters) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  dispatch({
    type: SET_FILTERS,
    payload: filters,
  });
  dispatch({ type: FILTER_CHARGES });
  dispatch({ type: CLEAR_ERRORS });
};

export const clearFilters = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  dispatch({ type: CLEAR_FILTERS });
  dispatch({ type: RESET_CHARGES });
  dispatch({ type: CLEAR_ERRORS });
};
