export let isManager = (permissionType) => {
  return permissionType === "manager";
};

export let isUser = (permissionType) => {
  return permissionType === "user";
};

export let isSub = (permissionType) => {
  return permissionType === "sub";
};

export let notManager = (permissionType) => {
  return permissionType !== "manager";
};

export let notUser = (permissionType) => {
  return permissionType !== "user";
};

export let notSub = (permissionType) => {
  return permissionType !== "sub";
};
