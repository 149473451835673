import React, { useState } from "react";
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import { PercentFormat } from "../util/PercentFormat";

import { useDispatch, useSelector } from "react-redux";
import { createDropdown } from "../redux/actions/dataActions";

const Manage = () => {
  const dispatch = useDispatch();
  const [manageOption, setManageOption] = useState("project");
  const [name, setName] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const errors = useSelector((state) => state.UI.errors);

  let handleChange = (event) => {
    setManageOption(event.target.value);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    const dropdown = {
      [manageOption]: name,
    };

    if (manageOption === "project") dropdown["taxRate"] = taxRate;

    console.log(dropdown);

    dispatch(createDropdown(dropdown));
    setName("");
    setTaxRate("");
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "1" }} />
      <div style={{ flex: "1" }}>
        <Typography
          variant="h4"
          style={{ margin: "25px auto", textAlign: "center" }}
        >
          Manage
        </Typography>
        <FormControl style={{ margin: "10px auto" }}>
          <FormLabel>Options</FormLabel>
          <RadioGroup value={manageOption} onChange={handleChange}>
            <FormControlLabel
              value="project"
              control={<Radio />}
              label="Project"
            />
            <FormControlLabel
              value="responsibility"
              control={<Radio />}
              label="Responsibility"
            />
            <FormControlLabel
              value="vendor"
              control={<Radio />}
              label="Vendor"
            />
          </RadioGroup>
        </FormControl>
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{ textAlign: "center" }}
        >
          <TextField
            id="name"
            name="name"
            label="Name"
            value={name}
            helperText={errors.name}
            error={errors.name ? true : false}
            onChange={(event) => setName(event.target.value)}
            fullWidth
            color="secondary"
            style={{ margin: "10px auto" }}
          />
          <TextField
            id="taxRate"
            label="Tax Rate"
            name="taxRate"
            value={taxRate}
            helperText={errors.taxRate}
            error={errors.taxRate ? true : false}
            onChange={(event) => setTaxRate(event.target.value)}
            fullWidth
            disabled={manageOption !== "project"}
            InputProps={{
              inputComponent: PercentFormat,
            }}
            color="secondary"
            style={{ margin: "10px auto" }}
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            style={{ margin: "25px auto" }}
          >
            Submit
          </Button>
        </form>
      </div>
      <div style={{ flex: "1" }} />
    </div>
  );
};

export default Manage;
