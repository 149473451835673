import {
  SET_CHARGE,
  SET_RAW_CHARGES,
  FILTER_CHARGES,
  SET_SELECTED,
  CLEAR_SELECTED,
  SET_DROPDOWNS,
  CLEAR_DROPDOWNS,
  CLEAR_CHARGES,
  SET_SELECTED_RES,
  CLEAR_SELECTED_RES,
  SET_STATEMENT,
  CLEAR_STATEMENT,
  SET_STATEMENTS,
  CLEAR_STATEMENTS,
  SET_FILTERS,
  CLEAR_FILTERS,
  RESET_CHARGES
} from "../types";

const initialState = {
  charge: {},
  charges: [],
  rawCharges: [],
  filters: {},
  selected: [],
  dropdowns: {
    projects: [],
    vendors: [],
    responsibilities: []
  },
  fetchedDropdowns: false,
  statement: {
    responsibility: "",
    payDate: "",
    total: "",
    charges: []
  },
  selectedRes: "",
  statements: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CHARGE:
      return {
        ...state,
        charge: action.payload
      };
    case SET_RAW_CHARGES:
      return {
        ...state,
        rawCharges: action.payload
      };
    case FILTER_CHARGES:
      var tempCharges = state.rawCharges;
      Object.keys(state.filters).forEach(function(filterItem) {
        if (filterItem === "paid") {
          if (state.filters[filterItem]) {
            tempCharges = tempCharges.filter(
              charge => charge["payDate"] !== ""
            );
          } else {
            tempCharges = tempCharges.filter(
              charge => charge["payDate"] === ""
            );
          }
        } else {
          tempCharges = tempCharges.filter(
            charge => charge[filterItem] === state.filters[filterItem]
          );
        }
      });
      return {
        ...state,
        charges: tempCharges
      };
    case CLEAR_CHARGES:
      return {
        ...state,
        charges: []
      };
    case RESET_CHARGES:
      return {
        ...state,
        charges: state.rawCharges
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {}
      };
    case SET_SELECTED:
      return {
        ...state,
        selected: action.payload
      };
    case CLEAR_SELECTED:
      return {
        ...state,
        selected: []
      };
    case SET_SELECTED_RES:
      return {
        ...state,
        selectedRes: action.payload
      };
    case CLEAR_SELECTED_RES:
      return {
        ...state,
        selectedRes: ""
      };
    case SET_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.payload,
        fetchedDropdowns: true
      };
    case CLEAR_DROPDOWNS:
      return {
        ...state,
        dropdowns: {
          projects: [],
          vendors: [],
          responsibilities: []
        },
        fetchedDropdowns: false
      };
    case SET_STATEMENT:
      return {
        ...state,
        statement: action.payload
      };
    case CLEAR_STATEMENT:
      return {
        ...state,
        statement: {
          responsibility: "",
          payDate: "",
          total: "",
          charges: []
        }
      };
    case SET_STATEMENTS:
      return {
        ...state,
        statements: action.payload
      };
    case CLEAR_STATEMENTS:
      return {
        ...state,
        statements: []
      };
    default:
      return state;
  }
}
