import React, { Component } from "react";

// Util
import { stableSort, getSorting } from "../util/sorting";
import _ from "lodash";
import NumberFormat from "react-number-format";
import { round } from "../util/round";

// Components
import { EnhancedTableToolbar } from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";

// MUI
import withStyles from "@material-ui/core/styles/withStyles";
import { lighten } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../util/theme";

// Redux
import { connect } from "react-redux";
import {
  payCharges,
  deleteCharges,
  fetchCharges,
  setSelected,
  createStatement,
  fetchDropdowns,
  setFilters,
  clearFilters,
} from "../redux/actions/dataActions";
import { clearErrors } from "../redux/actions/uiActions";

// Styles
const styles = {
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  tableText: {
    fontSize: 12,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  grayedOut: {
    pointerEvents: "none",
    opacity: 0.25,
  },
  notGrayedOut: {
    opacity: 1,
  },
  tableRowHovered: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.main, 0.85).concat(
        "!important"
      ),
    },
  },
  tableRow: {},
};

// Table Column Headers
const headers = [
  {
    label: "Responsibility",
    id: "responsibility",
    currency: false,
    disablePadding: false,
  },
  {
    label: "Invoice Date",
    id: "invoiceDate",
    currency: false,
    disablePadding: false,
  },
  {
    label: "Project",
    id: "project",
    currency: false,
    disablePadding: false,
  },
  {
    label: "Invoice Amount",
    id: "invoiceAmount",
    currency: true,
    disablePadding: false,
  },
  {
    label: "Invoice Number",
    id: "invoiceNumber",
    currency: false,
    disablePadding: false,
  },
  {
    label: "Vendor",
    id: "vendor",
    currency: false,
    disablePadding: false,
  },
  {
    label: "Tax Rate",
    id: "taxRate",
    currency: false,
    disablePadding: false,
  },
  {
    label: "Calculated Amount",
    id: "calculatedAmount",
    currency: true,
    disablePadding: false,
  },
  {
    label: "Description",
    id: "description",
    currency: false,
    disablePadding: false,
  },
  {
    label: "File",
    id: "attachment",
    currency: false,
    disablePadding: false,
  },
  {
    label: "Date Paid",
    id: "payDate",
    currency: false,
    disablePadding: false,
  },
];

export class home extends Component {
  state = {
    order: "desc",
    orderBy: "invoiceDate",
    page: 0,
    rowsPerPage: 10,
    total: 0,
    modalOpen: false,
    project: "",
    responsibility: "",
    paid: "",
  };

  componentDidMount() {
    this.props.fetchCharges();
    if (!this.props.fetchedDropdowns) this.props.fetchDropdowns();
    this.props.clearFilters();
  }

  handleRequestSort = (event, property) => {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc" });
    this.setState({ orderBy: property });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleChange = (name) => (event) => {
    switch (name) {
      case "project":
        this.setState({ [name]: event.target.value });
        break;
      default:
        this.setState({
          [event.target.name]: event.target.value,
        });
    }
  };

  render() {
    const {
      classes,
      UI: { loading },
      permissionType,
      charges,
      selected,
      setSelected,
      selectedRes,
      deleteCharges,
      createStatement,
      payCharges,
      dropdowns,
      setFilters,
      clearFilters,
      filters,
    } = this.props;

    let handleClick = (event, charge) => {
      const selectedIndex = selected.indexOf(charge.chargeId);
      let newSelected = [];
      let newTotal = this.state.total;

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, charge.chargeId);
        newTotal += round(charge.calculatedAmount);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        newTotal -= round(charge.calculatedAmount);
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        newTotal -= round(charge.calculatedAmount);
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
        newTotal -= round(charge.calculatedAmount);
      }
      setSelected(newSelected, charge.responsibility);

      this.setState({
        total: round(newTotal),
      });
    };

    let isSelected = (id) => selected.indexOf(id) !== -1;

    let handlePayCharges = (payDate) => {
      payCharges({
        payDate,
        chargeIds: selected,
      });
      var selectedCharges = [];
      var total = 0;
      selected.forEach((cid) => {
        charges.find((charge) => {
          if (charge.chargeId === cid) {
            selectedCharges.push(charge);
            total += round(charge.calculatedAmount);
          }
        });
      });
      total = round(total);
      createStatement({
        payDate,
        responsibility: selectedRes,
        charges: selectedCharges,
        total,
        count: selectedCharges.length,
      });
      this.setState({ total: 0 });
      clearErrors();
    };

    let handleDeleteCharges = () => {
      const charges = {
        chargeIds: selected,
      };
      deleteCharges(charges);
      this.setState({ total: 0 });
    };

    let handleFilterOpen = () => {
      var tempState = {};
      if (!_.isEmpty(filters)) {
        Object.keys(filters).forEach(function (key) {
          tempState[key] = filters[key];
        });
      }
      this.setState(Object.assign(tempState, { modalOpen: true }));
    };

    let handleFilterClose = () => {
      if (_.isEmpty(filters)) {
        this.setState({
          modalOpen: false,
          responsibility: "",
          project: "",
          paid: "",
        });
      } else {
        var tempState = {};
        Object.keys(filters).forEach(function (key) {
          tempState[key] = filters[key];
        });
        this.setState(Object.assign(tempState, { modalOpen: false }));
      }
    };

    let handleFilterSubmit = () => {
      this.setState({ modalOpen: false });
      var tempFilters = {};
      if (this.state.responsibility !== "")
        tempFilters["responsibility"] = this.state.responsibility;
      if (this.state.project !== "")
        tempFilters["project"] = this.state.project;
      if (this.state.paid !== "") tempFilters["paid"] = this.state.paid;
      if (!_.isEqual(tempFilters, filters)) setFilters(tempFilters);
    };

    let handleFilterReset = () => {
      if (!_.isEmpty(filters)) {
        clearFilters();
        this.setState({ responsibility: "", project: "", paid: "" });
      }
    };

    return (
      <div>
        {loading && (
          <CircularProgress
            color="primary"
            style={{
              position: "absolute",
              zIndex: 15,
              top: "50%",
              left: "50%",
            }}
          />
        )}
        <Dialog
          open={this.state.modalOpen}
          onClose={handleFilterClose}
          fullWidth
        >
          <DialogTitle>Filter</DialogTitle>
          <DialogContent>
            <TextField
              id="responsibility"
              name="responsibility"
              select
              label="Responsibility"
              value={this.state.responsibility}
              className={classes.textField}
              onChange={this.handleChange()}
              fullWidth
              style={{ marginTop: 10 }}
            >
              {dropdowns.responsibilities.map((responsibility) => (
                <MenuItem key={responsibility} value={responsibility}>
                  {responsibility}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="project"
              name="project"
              select
              label="Project"
              value={this.state.project}
              onChange={this.handleChange("project")}
              fullWidth
              style={{ marginTop: 10 }}
            >
              {dropdowns.projects.map((project) => (
                <MenuItem key={project.value} value={project.value}>
                  {project.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="paid"
              name="paid"
              select
              label="Paid?"
              value={this.state.paid}
              onChange={this.handleChange()}
              fullWidth
              style={{ marginTop: 10 }}
            >
              <MenuItem value={true}>Show Only Paid</MenuItem>
              <MenuItem value={false}>Show Only Not Paid</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFilterClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleFilterSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <div style={loading ? styles.grayedOut : styles.notGrayedOut}>
          <Paper>
            <EnhancedTableToolbar
              numSelected={selected.length}
              title="Backcharges"
              total={this.state.total}
              loading={loading}
              handlePayCharges={handlePayCharges}
              handleDeleteCharges={handleDeleteCharges}
              handleFilterCharges={handleFilterOpen}
              handleFilterReset={handleFilterReset}
              permissionType={permissionType}
              resetDisabled={!_.isEmpty(filters)}
            />
            <Table
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                headCells={headers}
                checkboxPadding
              />
              <TableBody>
                {stableSort(
                  charges,
                  getSorting(this.state.order, this.state.orderBy)
                )
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((charge, index) => {
                    const isItemSelected = isSelected(charge.chargeId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover={charge.payDate === ""}
                        onClick={(event) => {
                          if (
                            charge.payDate === "" &&
                            (charge.responsibility === selectedRes ||
                              selectedRes === "")
                          )
                            handleClick(event, charge);
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={charge.chargeId}
                        selected={isItemSelected}
                        className={
                          charge.payDate === ""
                            ? classes.tableRowHovered
                            : classes.tableRow
                        }
                      >
                        <TableCell padding="checkbox">
                          {charge.payDate === "" && (
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              disabled={
                                charge.responsibility !== selectedRes &&
                                selectedRes !== ""
                              }
                            />
                          )}
                        </TableCell>
                        {headers.map((header, index) =>
                          header.currency ? (
                            <TableCell
                              className={classes.tableText}
                              align="left"
                              key={index}
                            >
                              <NumberFormat
                                value={round(charge[header.id])}
                                thousandSeparator
                                isNumericString
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType="text"
                              />
                            </TableCell>
                          ) : header.id === "attachment" ? (
                            <TableCell padding="checkbox" key={index}>
                              {charge.attachment && (
                                <a
                                  href={charge.attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton
                                    onClick={(event) => {
                                      //only allows the onClick to be '1 level' deep
                                      //ignores table row onClick
                                      event.stopPropagation();
                                    }}
                                  >
                                    <AttachmentIcon />
                                  </IconButton>
                                </a>
                              )}
                            </TableCell>
                          ) : (
                            <TableCell
                              className={classes.tableText}
                              align="left"
                              key={index}
                            >
                              {charge[header.id]}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={charges.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  UI: state.UI,
  permissionType: state.user.credentials.permissionType,
  charges: state.data.charges,
  selected: state.data.selected,
  selectedRes: state.data.selectedRes,
  fetchedDropdowns: state.data.fetchDropdowns,
  dropdowns: state.data.dropdowns,
  filters: state.data.filters,
});

const mapActionsToProps = {
  payCharges,
  deleteCharges,
  fetchCharges,
  setSelected,
  createStatement,
  fetchDropdowns,
  setFilters,
  clearFilters,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(home));
