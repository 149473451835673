import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { notSub, isManager } from "../util/permissions";

// MUI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Redux
import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/userActions";

export class Navbar extends Component {
  handleLogout = () => {
    this.props.logoutUser();
  };

  render() {
    const { authenticated, permissionType } = this.props;
    return (
      <AppBar className="no-print">
        <Toolbar style={{ display: "flex" }}>
          <div style={{ flex: "1" }}>
            <Typography variant="h6">5-STAR Backcharges</Typography>
          </div>
          <div style={{ flex: "2", display: "flex", justifyContent: "center" }}>
            {authenticated && (
              <div>
                {notSub(permissionType) && (
                  <Button
                    style={{ margin: 10 }}
                    color="inherit"
                    component={Link}
                    to="/"
                  >
                    Charges
                  </Button>
                )}
                {notSub(permissionType) && (
                  <Button
                    style={{ margin: 10 }}
                    color="inherit"
                    component={Link}
                    to="/add"
                  >
                    Add a charge
                  </Button>
                )}
                <Button
                  style={{ margin: 10 }}
                  color="inherit"
                  component={Link}
                  to="/statements"
                >
                  Statements
                </Button>
                {isManager(permissionType) && (
                  <Button
                    style={{ margin: 10 }}
                    color="inherit"
                    component={Link}
                    to="/manage"
                  >
                    Manage
                  </Button>
                )}
              </div>
            )}
          </div>
          <div
            style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}
          >
            {authenticated && (
              <Button
                color="inherit"
                onClick={this.handleLogout}
                component={Link}
                to="/login"
              >
                Logout
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  permissionType: state.user.credentials.permissionType,
});

const mapActionsToProps = { logoutUser };

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(Navbar);
