import React, { Component } from "react";

// Util
import NumberFormat from "react-number-format";

// redux
import { connect } from "react-redux";
import { deleteStatement } from "../redux/actions/dataActions";

// components
import EnhancedTable from "../components/EnhancedTable";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";

const headers = [
  {
    label: "Invoice Date",
    id: "invoiceDate",
    currency: false,
  },
  {
    label: "Project",
    id: "project",
    currency: false,
  },
  {
    label: "Vendor",
    id: "vendor",
    currency: false,
  },
  {
    label: "Invoice Number",
    id: "invoiceNumber",
    currency: false,
  },
  {
    label: "Description",
    id: "description",
    currency: false,
  },
  {
    label: "Invoice Amount",
    id: "invoiceAmount",
    currency: true,
  },
  {
    label: "Tax Rate",
    id: "taxRate",
    currency: false,
  },
  {
    label: "Calculated Amount",
    id: "calculatedAmount",
    currency: true,
  },
  {
    label: "File",
    id: "attachment",
    currency: false,
  },
];

export class statement extends Component {
  state = {
    order: "desc",
    orderBy: "invoiceDate",
  };

  handleRequestSort = (event, property) => {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc" });
    this.setState({ orderBy: property });
  };
  render() {
    const {
      UI: { loading },
      statement,
      permissionType,
      deleteStatement,
    } = this.props;
    return (
      <div>
        {loading && (
          <CircularProgress
            color="primary"
            style={{
              position: "absolute",
              zIndex: 15,
              top: "50%",
              left: "50%",
            }}
          />
        )}
        <EnhancedTable
          rows={statement.charges}
          headers={headers}
          title={`Charges: ${statement.responsibility} - ${statement.payDate}`}
          permissionType={permissionType}
          onDelete={() => deleteStatement(statement.id, this.props.history)}
          footer={
            <div>
              Total:{" "}
              <NumberFormat
                value={statement.total}
                thousandSeparator
                isNumericString
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                displayType="text"
              />
            </div>
          }
          handleClick={() => {}}
          order={this.state.order}
          orderBy={this.state.orderBy}
          handleRequestSort={this.handleRequestSort}
          hover={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  UI: state.UI,
  statement: state.data.statement,
  permissionType: state.user.credentials.permissionType,
});

const mapActionsToProps = {
  deleteStatement,
};

export default connect(mapStateToProps, mapActionsToProps)(statement);
