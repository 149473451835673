import React, { Component } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { PercentFormat } from "../util/PercentFormat";
import { CurrencyFormat } from "../util/CurrencyFormat";
import { round } from "../util/round";

// MUI
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";

// Redux
import { connect } from "react-redux";
import { createCharge, fetchDropdowns } from "../redux/actions/dataActions";

const styles = {
  formContainer: {
    textAlign: "center",
  },
  pageTitle: {
    margin: "25px auto",
  },
  textField: {
    margin: "10px auto",
    textAlign: "left",
  },
  uploader: {
    margin: "20px auto",
    textAlign: "left",
  },
  button: {
    margin: "25px auto",
    position: "relative",
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progress: {
    position: "absolute",
    color: "white",
  },
};

export class add extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      invoiceDate: null,
      invoiceNumber: "",
      responsibility: "",
      project: "",
      vendor: "",
      invoiceAmount: "",
      taxRate: "",
      markupRate: 5,
      taxIncluded: false,
      calculatedAmount: "",
      description: "",
      attachment: null,
    };
  }

  componentDidMount() {
    if (!this.props.fetchedDropdowns) this.props.fetchDropdowns();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const charge = {
      invoiceDate: this.state.invoiceDate,
      invoiceNumber: this.state.invoiceNumber,
      responsibility: this.state.responsibility,
      //AT THIS POINT, PROJECT IS STILL AN OBJECT WITH VALUE AND TAXRATE
      project: this.state.project.value,
      vendor: this.state.vendor,
      invoiceAmount: round(parseFloat(this.state.invoiceAmount)),
      taxRate: this.state.taxRate,
      taxIncluded: this.state.taxIncluded,
      markupRate: this.state.markupRate,
      calculatedAmount: round(parseFloat(this.state.calculatedAmount)),
      description: this.state.description,
    };
    this.props.createCharge(charge, this.state.attachment, this.props.history);
  };

  calculateTax = (taxIncluded, invoiceAmount, taxRate, markupRate) => {
    if (taxIncluded || invoiceAmount * (1 + taxRate / 100) === 0) {
      this.setState({
        calculatedAmount: invoiceAmount,
      });
    } else {
      this.setState({
        calculatedAmount:
          invoiceAmount * (1 + taxRate / 100) * (1 + markupRate / 100),
      });
    }
  };

  handleChange = (name) => (event) => {
    switch (name) {
      case "taxIncluded":
        this.setState({ [name]: event.target.checked });
        this.calculateTax(
          event.target.checked,
          this.state.invoiceAmount,
          this.state.taxRate,
          this.state.markupRate
        );
        break;
      case "invoiceAmount":
        this.setState({ [name]: event.target.value });
        this.calculateTax(
          this.state.taxIncluded,
          event.target.value,
          this.state.taxRate,
          this.state.markupRate
        );
        break;
      case "taxRate":
        this.setState({ [name]: event.target.value });
        this.calculateTax(
          this.state.taxIncluded,
          this.state.invoiceAmount,
          event.target.value,
          this.state.markupRate
        );
        break;
      case "markupRate":
        this.setState({ [name]: event.target.value });
        this.calculateTax(
          this.state.taxIncluded,
          this.state.invoiceAmount,
          this.state.taxRate,
          event.target.value
        );
        break;
      case "project":
        this.setState({ [name]: event.target.value });
        this.setState({ taxRate: event.target.value.taxRate });
        this.calculateTax(
          this.state.taxIncluded,
          this.state.invoiceAmount,
          event.target.value.taxRate,
          this.state.markupRate
        );
        break;
      case "attachment":
        const attachment = event.target.files[0];
        if (attachment) {
          const formData = new FormData();
          formData.append("attachment", attachment, attachment.name);
          this.setState({ attachment: formData });
        } else {
          this.setState({ attachment: null });
        }
        break;
      default:
        this.setState({
          [event.target.name]: event.target.value,
        });
    }
  };

  render() {
    const {
      classes,
      dropdowns,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    return (
      <Grid container className={classes.formContainer}>
        <Grid item sm />
        <Grid item sm>
          <Typography variant="h4" className={classes.pageTitle}>
            Add A Charge
          </Typography>
          <form noValidate onSubmit={this.handleSubmit}>
            <TextField
              id="responsibility"
              name="responsibility"
              select
              label="Responsibility"
              value={this.state.responsibility}
              helperText={errors.responsibility}
              error={errors.responsibility ? true : false}
              className={classes.textField}
              onChange={this.handleChange()}
              fullWidth
              color="secondary"
            >
              {dropdowns.responsibilities.map((responsibility) => (
                <MenuItem key={responsibility} value={responsibility}>
                  {responsibility}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="project"
              name="project"
              select
              label="Project"
              value={this.state.project}
              helperText={errors.project}
              className={classes.textField}
              error={errors.project ? true : false}
              onChange={this.handleChange("project")}
              fullWidth
              color="secondary"
            >
              {dropdowns.projects.map((project) => (
                <MenuItem key={project.value} value={project}>
                  {project.value}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="vendor"
              name="vendor"
              select
              label="Vendor"
              value={this.state.vendor}
              helperText={errors.vendor}
              className={classes.textField}
              error={errors.vendor ? true : false}
              onChange={this.handleChange()}
              fullWidth
              color="secondary"
            >
              {dropdowns.vendors.map((vendor) => (
                <MenuItem key={vendor} value={vendor}>
                  {vendor}
                </MenuItem>
              ))}
            </TextField>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                name="invoiceDate"
                format="MM/dd/yyyy"
                margin="none"
                id="date-picker-inline"
                label="Invoice Date"
                className={classes.textField}
                value={this.state.invoiceDate}
                helperText={errors.invoiceDate}
                error={errors.invoiceDate ? true : false}
                onChange={(event, date) =>
                  this.setState({
                    invoiceDate: date,
                  })
                }
                fullWidth
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                color="secondary"
              />
            </MuiPickersUtilsProvider>

            <TextField
              id="invoiceNumber"
              name="invoiceNumber"
              type="string"
              label="Invoice Number"
              className={classes.textField}
              helperText={errors.invoiceNumber}
              error={errors.invoiceNumber ? true : false}
              value={this.state.invoiceNumber}
              onChange={this.handleChange()}
              fullWidth
              color="secondary"
            />

            <TextField
              id="invoiceAmount"
              name="invoiceAmount"
              label="Invoice Amount"
              className={classes.textField}
              helperText={errors.invoiceAmount}
              error={errors.invoiceAmount ? true : false}
              value={this.state.invoiceAmount}
              onChange={this.handleChange("invoiceAmount")}
              fullWidth
              InputProps={{
                inputComponent: CurrencyFormat,
              }}
              color="secondary"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.taxIncluded}
                  onChange={this.handleChange("taxIncluded")}
                  value="taxIncluded"
                  color="secondary"
                />
              }
              label="Already Taxed?"
            />

            <TextField
              id="taxRate"
              label="Tax Rate"
              name="taxRate"
              className={classes.textField}
              value={this.state.taxRate}
              helperText={errors.taxRate}
              error={errors.taxRate ? true : false}
              onChange={this.handleChange("taxRate")}
              fullWidth
              disabled={this.state.taxIncluded}
              InputProps={{
                inputComponent: PercentFormat,
              }}
              color="secondary"
            />

            <TextField
              id="markupRate"
              label="Markup Rate"
              name="markupRate"
              className={classes.textField}
              value={this.state.markupRate}
              helperText={errors.markupRate}
              error={errors.markupRate ? true : false}
              onChange={this.handleChange("markupRate")}
              fullWidth
              disabled={this.state.taxIncluded}
              InputProps={{
                inputComponent: PercentFormat,
              }}
              color="secondary"
            />

            <TextField
              id="calculatedAmount"
              label="Calculated Amount"
              value={this.state.calculatedAmount}
              helperText={errors.calculatedAmount}
              error={errors.calculatedAmount ? true : false}
              className={classes.textField}
              margin="normal"
              InputProps={{
                readOnly: true,
                inputComponent: CurrencyFormat,
              }}
              fullWidth
              color="secondary"
            />

            <TextField
              id="standard-multiline-flexible"
              label="Description"
              name="description"
              value={this.state.description}
              helperText={errors.description}
              error={errors.description ? true : false}
              onChange={this.handleChange()}
              className={classes.textField}
              multiline
              rowsMax="4"
              margin="normal"
              fullWidth
              color="secondary"
            />

            <div className={classes.uploader}>
              <input
                type="file"
                id="attachment"
                onChange={this.handleChange("attachment")}
              />
              {errors.attachment && (
                <Typography variant="body2" className={classes.customError}>
                  {errors.attachment}
                </Typography>
              )}
            </div>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={loading}
            >
              Submit
              {loading && (
                <CircularProgress className={classes.progress} size={30} />
              )}
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

add.propTypes = {
  classes: PropTypes.object.isRequired,
  createCharge: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  dropdowns: state.data.dropdowns,
  fetchedDropdowns: state.data.fetchDropdowns,
});

const mapActionsToProps = {
  createCharge,
  fetchDropdowns,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(add));
