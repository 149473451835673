import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { notSub } from "./permissions";

const NotSubRoute = ({
  component: Component,
  authenticated,
  permissionType,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated ? (
        notSub(permissionType) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/statements" />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  permissionType: state.user.credentials.permissionType,
});

export default connect(mapStateToProps)(NotSubRoute);
